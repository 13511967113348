/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/illustrations/artificial-intelligence-ai-robot-2228610/"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "신정규 님"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "https://github.com/inureyes"), "\n", React.createElement(_components.li, null, "https://twitter.com/inureyes"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.lablup.com/"
  }, "Lablup(래블업)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.backend.ai/"
  }, "Backend.AI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/inureyes/building-large-language-model-at-scale"
  }, "2023 LangCon 발표 / 신정규")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2023/05/0516-2/"
  }, "AI 골드러시에 리바이스가 되려는 ‘래블업’")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "AI 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.itworld.co.kr/news/235494"
  }, "AI에 대한 우호적 인식")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bbc.com/korean/articles/crgm8d787l7o"
  }, "AI에 대한 부정적 인식")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://slownews.kr/91183"
  }, "AI를 과대해석하지 말자는 인식")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.newstheai.com/news/articleView.html?idxno=3597"
  }, "“값비싼 초거대AI 뒤로 ‘가성비’ 모델 뜬다”")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
